<template>
  <div class="m-goals">
    <div class="columns">
      <div class="column is-one-fifth">
        <section class="section">
          <div class="container">
            <aside class="b-menu">
              <b-menu>
                <b-menu-list :label="$t('generic.actions')">
                  <b-menu-item :label="$t('goals.list')" tag="router-link" :to="{ name: 'goals-list' }"></b-menu-item>
                  <b-menu-item
                    :label="$t('goals.createGoal')"
                    tag="router-link"
                    :to="{ name: 'goal-new' }"
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </aside>
          </div>
        </section>
      </div>
      <div class="column">
        <section class="section">
          <div class="container">
            <router-view></router-view>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Goals'
};
</script>
